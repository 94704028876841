import { $, component$, useStore } from '@builder.io/qwik';
import { Auth, AuthScreenId } from './Auth';
import type { HSOverlay, ICollectionItem } from 'preline';

export const AuthModal = component$(() => {
  const { close } = useAuthModal();

  const handleSuccess = $(() => {
    close();
  });

  return (
    <>
      <div class="hidden" data-hs-overlay="#hs-auth-modal" />
      <div
        id="hs-auth-modal"
        class="font-inter hs-overlay hs-overlay-open:flex pointer-events-none fixed start-0 top-0 z-[80] hidden size-full items-center justify-center overflow-y-auto overflow-x-hidden"
      >
        <div class="hs-overlay-open:mt-0 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 container flex items-center justify-center py-4 opacity-0 transition-all ease-out">
          <Auth onSuccess$={handleSuccess} />
        </div>
      </div>
    </>
  );
});

const getTriggerElement = () =>
  document.querySelector(
    '[data-hs-overlay="#hs-auth-modal"]'
  ) as HTMLDivElement;

const getInstance = () => {
  const instance = window.HSOverlay.getInstance(
    getTriggerElement(),
    true
  ) as ICollectionItem<HSOverlay> | null;

  return instance!.element;
};

export const useAuthModal = () => {
  const store = useStore<{ screen: AuthScreenId }>({ screen: 'signin' });

  const open = $((_screen?: AuthScreenId) => {
    const instance = getInstance();
    store.screen = _screen ?? 'signin';
    instance.open();
  });

  const close = $(() => {
    const instance = getInstance();
    instance.close();
  });

  const on = $((event: 'open' | 'close', callback: () => void) => {
    const instance = getInstance();
    instance.on(event, callback);
  });

  return { store, open, close, on };
};

import axios from 'axios';
import staticData from './index.json';
import { routeLoader$, useLocation } from '@builder.io/qwik-city';
import { useComputed$ } from '@builder.io/qwik';

export const useCurrentHref = () => {
  const location = useLocation();
  return useComputed$(() =>
    location.url.href.replace(`${location.url.hash}`, '')
  );
};

export const getToken = async (): Promise<string | null> => {
  const response = await axios.post(
    `${global.ENV.PUBLIC_SITE_URL}/oauth/token`,
    {
      client_id: global.ENV.PUBLIC_AUTH_CLIENT_ID,
      client_secret: global.ENV.PUBLIC_AUTH_CLIENT_SECRET,
      grant_type: 'client_credentials'
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  );

  return response.data.access_token;
};

import { getDataVariant } from '../../lib/data-variants.lib';

// eslint-disable-next-line qwik/loader-location
export const useAuthDataLoader = routeLoader$(() =>
  // TODO: Get variant from LD
  getDataVariant(staticData, 'from-questions')
);

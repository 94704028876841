/* eslint-disable qwik/no-use-visible-task */
import { $, QRL, useOnWindow } from '@builder.io/qwik';

export const usePreline = (qrl: QRL<() => void>) => {
  useOnWindow(
    'load',
    $(() => {
      if (window.__prelineLoaded) {
        qrl();
      } else {
        import('preline').then(mod => {
          mod.HSStaticMethods.autoInit('all');
          window.__prelineLoaded = true;
          qrl();
        });
      }
    })
  );
};

import axios, { isAxiosError } from 'axios';
import { Button } from '../ui/Button';
import {
  FormError,
  formAction$,
  reset,
  useForm,
  valiForm$
} from '@modular-forms/qwik';
import { Input } from '../ui/Input';
import { type Input as ValibotInput, pick } from 'valibot';
import { component$, useSignal, useTask$ } from '@builder.io/qwik';
import { getToken, useAuthDataLoader } from './utils';
import { signinSchema } from './SigninEmailForm';

export type RecoveryForm = ValibotInput<typeof recoverySchema>;

export const recoverySchema = pick(signinSchema, ['email']);

export const useRecoveryFormAction = formAction$<RecoveryForm>(async values => {
  try {
    const token = await getToken();

    if (!token) {
      throw new FormError<RecoveryForm>(
        'Falha na obtenção do token. Por favor, contate o suporte.'
      );
    }

    await axios.post(
      `${global.ENV.PUBLIC_API_URL}/users/send_reset_password_email`,
      values,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const { response } = error;
      return response?.data.error ?? response?.data.errors.message;
    } else {
      throw new FormError<RecoveryForm>(
        'Um erro inesperado ocorreu. Por favor, entre em contato com o suporte!'
      );
    }
  }
}, valiForm$(recoverySchema));

export const RecoveryEmailForm = component$(
  ({ onSuccess$ }: { onSuccess$?: () => void }) => {
    const { value: data } = useAuthDataLoader();
    const initialState = useSignal({
      email: ''
    });

    const action = useRecoveryFormAction();

    const [state, { Form, Field }] = useForm<RecoveryForm>({
      action,
      loader: initialState,
      validate: valiForm$(recoverySchema)
    });

    useTask$(({ track }) => {
      const status = track(() => action.status);

      if (state.invalid || status !== 200 || typeof onSuccess$ !== 'function') {
        return;
      }

      reset(state);
      onSuccess$();
    });

    return (
      <div class="relative flex w-full h-full flex-col items-center justify-start gap-5 mt-4">
        <div class="flex flex-col h-full gap-7 self-stretch">
          <Form class="flex w-full h-full justify-center items-center flex-col gap-5">
            <div class="max-w-[365px] w-full mx-auto">
              <Field name="email">
                {(field, props) => (
                  <Input
                    {...props}
                    class="focus:placeholder-neutral-300"
                    autoComplete="username"
                    disabled={state.submitting}
                    placeholder="Seu email*"
                    size="lg"
                    state={
                      field.touched && state.invalid
                        ? field.error
                          ? 'error'
                          : 'success'
                        : 'default'
                    }
                    type="email"
                    value={field.value}
                    {...(action.value?.response?.status &&
                      action.value?.response?.status === 'error' && {
                        state: 'error',
                        label: action.value.response.message
                      })}
                  />
                )}
              </Field>
            </div>
            <Button
              class="max-w-[310px] self-center"
              disabled={state.invalid || state.submitting || !state.dirty}
              loading={state.submitting}
              size="xl"
              type="submit"
              variant="brand"
            >
              {data.recovery.buttonTitle}
            </Button>
          </Form>
        </div>
      </div>
    );
  }
);
